/*
Template Name: Dason - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Dashboard Init Js File
*/

// Chart color change function
function ChartColorChange(chart, chartId) {
    document.querySelectorAll('input[name=theme]').forEach((elem) => {
        elem.addEventListener('change', function(e) {
            const colors = getChartColorsArray(chartId);
            chart.updateOptions({ colors: colors });
        });
    });
}

const defaultColors = {
    primary: '#556ee6',
    success: '#34c38f',
    warning: '#f1b44c',
    danger: '#f46a6a',
    info: '#50a5f1'
};

// get colors array from the string
function getChartColorsArray(chartId) {
    const colors = $(chartId).attr('data-colors');
    if (!colors) {
        return [defaultColors.primary, defaultColors.success];
    }
    try {
        const parsedColors = JSON.parse(colors);
        return parsedColors.map(function(value) {
            const newValue = value.replace(' ', '');
            if(newValue.indexOf('--') !== -1) {
                const color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
                return color || defaultColors.primary;
            }
            return newValue;
        });
    } catch (error) {
        console.error('Error parsing colors for', chartId, error);
        return [defaultColors.primary, defaultColors.success];
    }
}

// Initialize mini charts
function initializeMiniChart(chartId, seriesData) {
    const chartColors = getChartColorsArray(chartId);
    const chartOptions = {
        series: seriesData,
        chart: {
            type: 'donut',
            height: 110,
        },
        colors: chartColors,
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        }
    };

    const chart = new ApexCharts(document.querySelector(chartId), chartOptions);
    chart.render();
    ChartColorChange(chart, chartId);
    return chart;
}

// Initialize all mini charts
const miniChart1 = initializeMiniChart('#mini-chart1', [60, 40]);
const miniChart2 = initializeMiniChart('#mini-chart2', [30, 55]);
const miniChart3 = initializeMiniChart('#mini-chart3', [65, 45]);

// Export for use in other files if needed
window.dashboardCharts = {
    miniChart1,
    miniChart2,
    miniChart3
};

//
// Market Overview
//
var barchartColors = getChartColorsArray('#market-overview');
var options = {
    series: [{
        name: 'Profit',
        data: [12.45, 16.2, 8.9, 11.42, 12.6, 18.1, 18.2, 14.16, 11.1, 8.09, 16.34, 12.88]
    }, {
        name: 'Loss',
        data: [-11.45, -15.42, -7.9, -12.42, -12.6, -18.1, -18.2, -14.16, -11.1, -7.09, -15.34, -11.88]
    }],
    chart: {
        type: 'bar',
        height: 400,
        stacked: true,
        toolbar: {
            show: false
        },
    },
    plotOptions: {
        bar: {
            columnWidth: '20%',
        },
    },
    colors: barchartColors,
    fill: {
        opacity: 1
    },
    dataLabels: {
        enabled: false,
    },
    legend: {
        show: false,
    },
    yaxis: {
        labels: {
            formatter: function (y) {
                return y.toFixed(0) + '%';
            }
        }
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: {
            rotate: -90
        }
    }
};

var chart = new ApexCharts(document.querySelector('#market-overview'), options);
chart.render();
ChartColorChange(chart, '#market-overview');